import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    barsButton: {
        "&:focus": {
            outline: "none",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "18px",
        height: "18px",
        background: "transparent",
    },
    barsButtonOpened: {
        height: "17px",
    },
    barOne: {
        width: "100%",
        height: "2px",
        background: "#909090",
        transition: "all 0.3s linear",
        position: "relative",
        transformOrigin: "1px",
        transform: "rotate(0)",
    },
    barTwo: {
        opacity: "1",
        transform: "translateX(0)",
    },
    barThree: {
        transform: "rotate(0)",
    },
    barOneOpened: {
        transform: "rotate(45deg)",
    },
    barTwoOpened: {
        opacity: "0",
        transform: "translateX(20px)",
    },
    barThreeOpened: {
        transform: "rotate(-45deg)",
    },
    mobileWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "0px 20px",
    },
    mobileNavbar: {
        padding: "22px 0px",
        textAlign: "center",
        width: "100%",
    },
    topBar: {
        zIndex: "1",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        backgroundColor: "white",
        // animate nav opening details start
        opacity: "0",
        visibility: "hidden",
        transition: theme.transitions.create(["opacity", "visibility"], { duration: "0.35s", }),
        overflow: "hidden",
        fontSize: "16px",
    },
    topBarOpened: {
        // animate nav opening details end
        height: "auto",
        opacity: "1",
        visibility: "visible",
        paddingBottom: theme.spacing(3),
        boxShadow: "0px 6px 8px -2px rgb(0 0 0 / 14%)",
    },
    mobileLogoSize: {
        width: "69px",
        height: "29px",
    },
    openPositionsMobile: {
        width: "95%",
        textAlign: "center",
        paddingTop: theme.spacing(3),
    },
    menuLink: {
        paddingTop: theme.spacing(3),
    },
}));
