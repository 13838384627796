import React from "react";
import cn from "clsx";

import useStyles from "./TopBar.styles";

import Logo from "../Logo";
import CommonLink from "../../CommonLink/CommonLink";
import OpenPositionsButton from "../OpenPositionsButton/OpenPositionsButton";

const TopBar = ({ logo, isOpen, toggleTopBar, links }) => {
  const classes = useStyles();

  return (
      <nav className={classes.mobileNavbar}>
          <div>
            <div className={classes.mobileWrapper}>
                <Logo url={logo.url} sizeClass={classes.mobileLogoSize} />
                <button className={isOpen ? cn([classes.barsButton, classes.barsButtonOpened]) : classes.barsButton} onClick={toggleTopBar}>
                    <div className={isOpen ? cn([classes.barOne, classes.barOneOpened]) : classes.barOne} />
                    <div className={isOpen ? cn([classes.barOne, classes.barTwoOpened]) : cn([classes.barOne, classes.barTwo])} />
                    <div className={isOpen ? cn([classes.barOne, classes.barThreeOpened]) : cn([classes.barOne, classes.barThree])} />
                </button>
            </div>
            <div className={isOpen ? cn([classes.topBar, classes.topBarOpened]) : classes.topBar}>
                {links.map(link => <CommonLink className={classes.menuLink} link={link} toggleTopBar={toggleTopBar} /> )}
                <div className={classes.openPositionsMobile}>
                    <OpenPositionsButton toggleTopBar={toggleTopBar} />
                </div>
            </div>
          </div>
      </nav>
  );
}

export default TopBar;

