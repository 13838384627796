import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    navbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "22px 126px",
        maxWidth: "1440px",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
            padding: "22px 50px"
        },
        [theme.breakpoints.down("sm")]: {
            padding: "22px 30px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "22px 0px"
        }
    },
    desktopLogoSize: {
        width: "85px",
        height: "36px"
    },
    openPositionsDesktop: {
        width: "145px",
        textAlign: "center"
    }
}));
