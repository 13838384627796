import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import AboutUs from "../components/AboutUs/AboutUs";
import SuccessStories from "../components/SuccessStories/SuccessStories";
import Careers from "../components/Careers/Careers";
import EuropeanUnionFunding from "../components/EuropeanUnionFunding/EuropeanUnionFunding";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import LifeAtRevel from "../components/LifeAtRevel/LifeAtRevel";
import OurStructure from "../components/OurStructure/OurStructure";
import TopBenefits from "../components/TopBenefits/TopBenefits";
import Navigation from "../components/Navigation/Navigation";
import ProductEngineering from "../components/ProductEngineering/ProductEngineering";

const sections = {
    Header,
    AboutUs,
    OurStructure,
    TopBenefits,
    LifeAtRevel,
    EuropeanUnionFunding,
    Footer,
    Careers,
    SuccessStories,
    ProductEngineering,
};

const camelToDashCase = (str) =>
    str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

const sectionsMap = Object.keys(sections).reduce(
    (obj, component) => ({
        ...obj,
        ["sections." + camelToDashCase(component)]: sections[component],
    }),
    {}
);

const renderSection = (section) => {
    const Section = sectionsMap[section.strapi_component];
    if (Section) {
        return <Section {...section} key={section.strapi_component} />;
    }
    return <h1 key={section.strapi_component}>{section.strapi_component}</h1>;
};

const IndexPage = ({ data }) => {
    const {
        strapiGlobal: { logo, navlinks, ...global },
        strapiHomepage: { sections },
    } = data;

    return (
        <>
            <SEO global={global} />
            <Navigation logo={logo.logo} links={navlinks} />
            {sections.map(renderSection)}
        </>
    );
};

export default IndexPage;

export const query = graphql`
    query IndexPageQuery {
        strapiGlobal {
            favicon {
                url
            }
            shareImage {
                url
            }
            metaTitle
            metaDescription
            title
            logo {
                logo {
                    url
                }
            }
            navlinks {
                label
                target
                url
            }
        }
        strapiHomepage {
            sections
        }
    }
`;
