import React from "react";

import cn from "clsx";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import { ChevronLeft } from "@material-ui/icons";

import useStyles from "./ModalHeader.styles";

const ModalHeader = ({ children, onBackClick, onClose, className }) => {
    const classes = useStyles();

    return (
        <AppBar
            position="sticky"
            color="default"
            className={cn([classes.root, className])}
            elevation={0}
        >
            <Toolbar variant="dense" disableGutters>
                {onBackClick ? (
                    <>
                        <IconButton onClick={onBackClick}>
                            <ChevronLeft />
                        </IconButton>
                        <Typography variant="h6">{children}</Typography>
                    </>
                ) : (
                    <Typography variant="h6">{children}</Typography>
                )}
                {onClose && (
                    <IconButton
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default ModalHeader;
