import { Typography, Link } from "@material-ui/core";
import cn from "clsx";
import React, { useState } from "react";

import SplitText from "../SplitText/SplitText";

import useStyles from "./EuropeanUnionFunding.styles";

const EuropeanUnionFunding = ({
    title,
    message,
    image,
    buttonExpanded,
    buttonCollapsed,
}) => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    const handleOnClick = () => setIsExpanded(!isExpanded);

    return (
        <div id={"customer-support"} className={classes.root}>
            <img src={image.url} className={classes.image} />

            <Typography
                component={"span"}
                variant="body1"
                className={classes.title}
            >
                {title}
            </Typography>

            {isExpanded ? (
                <SplitText
                    className={classes.message}
                    text={message}
                    variant="body1"
                />
            ) : null}

            <Link
                component={"span"}
                className={cn([
                    classes.link,
                    isExpanded ? classes.extraMargin : null,
                ])}
                onClick={handleOnClick}
            >
                {isExpanded ? buttonExpanded : buttonCollapsed}
            </Link>
        </div>
    );
};

export default EuropeanUnionFunding;
