import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "& ul": {
            display: "flex",
            fontSize: "16px",
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
                flexDirection: "column"
            }
        },
        "& li": {
            paddingRight: "32px",
            '&:last-child': {
                paddingRight: "0px",
            },
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
                padding: "0px 0px 24px 0px",
            }
        },
        width: "100%",
        margin: "0 auto",
        position: "fixed",
        top: 0,
        background: "white",
        zIndex: 1000,
        overflow: "hidden",
    },
}));
