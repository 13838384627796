import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    openPositionsButton: {
        border: "1px solid #e0e0e0",
        padding: "3px 0px",
        borderRadius: "4px",
        color: "#00A3E0",
        display: "flex",
        width: "100%",
        height: "35px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
    },
}));
