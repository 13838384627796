import React from "react";
import {Button, Typography} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import useStyles from "./Header.styles";
import cn from "clsx";
import { useMediaWidthUpFrom } from "../../utils/hooks";

const Header = ({ title, text, button, images, mobileImage }) => {
    const classes = useStyles();
    const isMdUp = useMediaWidthUpFrom("md");

    return (
        <div className={classes.root}>
            {!isMdUp &&
                <div className={classes.mobileImageWrapper}>
                    <img src={mobileImage.url} alt=""/>
                </div>
            }
            <div className={classes.headerContainer}>
                <div className={classes.textWrapper}>
                    <Typography
                        className={classes.title}
                        variant={isMdUp ? "h1" : "h2"}
                    >
                        {title.slice(0, 7)} <br/> {title.slice(8)}
                    </Typography>
                    <Typography
                        className={classes.text}
                        variant="body1"
                    >
                        {text}
                    </Typography>
                    <div className={classes.quizButtonWrapper}>
                        <Button
                            className={classes.quizButton}
                            target={button.target}
                            href={button.url}
                        >
                            {button.label}
                            <KeyboardBackspaceIcon className={classes.arrowIcon}></KeyboardBackspaceIcon>
                        </Button>
                    </div>
                </div>
                {isMdUp &&
                    <div className={classes.imagesContainer}>
                        <div className={classes.imagesWrapperFirst}>
                            <div>
                                <img src={images[0].url} alt={"header-image-1"} />
                            </div>
                            <div>
                                <img src={images[1].url} alt={"header-image-2"} />
                            </div>
                        </div>
                        <div className={cn([classes.imagesWrapperFirst, classes.imagesWrapperSecond])}>
                            <div>
                                <img src={images[2].url} alt={"header-image-3"} />
                            </div>
                            <div>
                                <img src={images[3].url} alt={"header-image-4"} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;
