import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        backgroundColor: "#FAFAFA",
        padding: theme.spacing(11.25, 0),
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: theme.spacing(6.25, 0),
        }
    },
    container: {
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: "0px 8px"
        }
    },
    gridContainer: {
        display: "grid",
        gridTemplateColumns: `${theme.spacing(45.375)}px ${theme.spacing(45.375)}px`,
        gridColumnGap: theme.spacing(10),
        gridRowGap: theme.spacing(11.25),
        justifyContent: "center",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "flex",
            gridRowGap: "54px",
            gridColumnGap: "unset",
            flexDirection: "column",
            gridTemplateColumns: "unset",
        },
    },
    paragraph: {
        letterSpacing: "0.5px",
        lineHeight: "24px",
        color: "rgba(0, 0, 0, 0.6)",
        textAlign: "justify",
        marginTop: "27px",
        fontSize: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: "20px",
        },
    }
}));
