import React from "react";

import cn from "clsx";

import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";

import useStyles from "./ModalWrapper.styles";

export const DrawerWrapper = ({
    children,
    isOpen,
    onClose,
    className = undefined,
}) => {
    const classes = useStyles();
    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            classes={{
                paper: cn([classes.drawerPaper, className]),
            }}
        >
            {children}
        </Drawer>
    );
};

export const DialogWrapper = ({
    children,
    isOpen,
    onClose,
    maxWidth = "md",
    className = undefined,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth
            classes={{ paper: cn([classes.dialogPaper, className]) }}
        >
            {children}
        </Dialog>
    );
};
