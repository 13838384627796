import React from "react";

import useStyles from "./Navbar.styles";
import Logo from "../Logo";
import OpenPositionsButton from "../OpenPositionsButton/OpenPositionsButton";
import CommonLink from "../../CommonLink/CommonLink";

const Navbar = ({ logo, toggleTopBar, links }) => {
    const classes = useStyles();

    return (
        <nav className={classes.navbar}>
            <Logo url={logo.url} sizeClass={classes.desktopLogoSize} />
            <div>
                <ul>
                    {links.map((link) => (
                        <li key={link.url}>
                            <CommonLink
                                link={link}
                                toggleTopBar={toggleTopBar}
                            />
                        </li>
                    ))}
                </ul>
            </div>
            <div className={classes.openPositionsDesktop}>
                <OpenPositionsButton toggleTopBar={toggleTopBar} />
            </div>
        </nav>
    );
};

export default Navbar;
