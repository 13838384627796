import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(8, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: theme.spacing(93.75),
        margin: "auto",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: theme.spacing(4, 1),
        },
    },
    title: {
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            maxWidth: theme.spacing(31.25),
            textAlign: "center",
        },
    },
    image: {
        height: theme.spacing(15),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: theme.spacing(31.25),
            objectFit: "contain",
        },
    },
    link: {
        cursor: "pointer",
        textDecoration: "underline",
        color: "#00A3E0",
    },
    extraMargin: {
        marginTop: theme.spacing(3),
    },
    "@keyframes fade": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
    message: {
        color: "rgba(0, 0, 0, 0.6)",
        animationName: "$fade",
        animationDuration: `0.5s`,
        animationTimingFunction: "linear",
        fontSize: "16px",
    },
}));
