import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: "#FFF",
        color: "rgba(0, 0, 0, 0.87)",
    },
    closeButton: {
        marginLeft: "auto",
        marginRight: "-17px",
    },
}));
