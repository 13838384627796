import React from "react";
import { Link } from "gatsby"

const Logo = ({ url, sizeClass }) => {
    return (
        <div>
            <Link to="/">
                <img className={sizeClass} src={url} alt={"logo"} />
            </Link>
        </div>
    );
};

export default Logo;
