import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(11, 15.75, 4.75),
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#17324D",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: theme.spacing(4, 1, 3.25),
        },
    },
    divider: {
        backgroundColor: theme.palette.secondary.contrastText,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(14.5),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: theme.spacing(3.25),
        },
    },
    color: {
        color: theme.palette.secondary.contrastText,
    },
    copyrightContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    logo: {
        cursor: "pointer",
        marginBottom: theme.spacing(2),
    },
    mediaContainer: {
        display: "inline-flex",
    },
    media: {
        border: "1.5px solid rgba(255, 255, 255, 0.2)",
        padding: theme.spacing(1.5),
        borderRadius: "50%",
        marginRight: theme.spacing(0.75),
        display: "flex",
        justifyContent: "center",
        minWidth: theme.spacing(5),
        cursor: "pointer",
    },
    socialMediaMessage: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(2.5),
    },
    socialMediaContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            alignItems: "start",
        },
    },
    sectionContainer: {
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            margin: theme.spacing(4.5, 0),
        },
    },
    extraPadding: {
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            paddingLeft: theme.spacing(8),
        },
    },
    section: {
        marginBottom: theme.spacing(2.5),
    },
    gridContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
    },
}));
