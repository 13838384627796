import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        paddingTop: "104px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            paddingTop: "80px",
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            paddingTop: "73px",
        },
    },
    headerContainer: {
        display: "flex",
        maxWidth: "1440px",
        width: "100%",
        gap: "117px",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 auto",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "block",
            gap: "0px",
            width: "100%",
            padding: "0px 8px",
        },
    },
    title: {
        lineHeight: "112px",
        letterSpacing: "-1.5px",
        marginBottom: "13px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            fontSize: "60px",
            lineHeight: "72px",
            letterSpacing: "-0.5px",
        },
    },
    text: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "16px",
        letterSpacing: "0.5px",
    },
    textWrapper: {
        paddingLeft: "126px",
        flex: "50%",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            paddingLeft: "0px",
            paddingTop: "20px",
        },
    },
    mobileImageWrapper: {
        display: "flex",
        justifyContent: "flex-end",
    },
    imagesContainer: {
        display: "flex",
        gridRowGap: "22px",
        flexDirection: "column",
        flex: "50%",
    },
    imagesWrapperFirst: {
        display: "flex",
        gap: "22px",
        alignItems: "flex-end",
    },
    imagesWrapperSecond: {
        alignItems: "flex-start",
        paddingLeft: "57px",
    },
    quizButton: {
        width: "100%",
        height: "40px",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        color: "rgba(255, 255, 255, 1)",
        background: "rgba(0, 163, 224, 1)",
        '&:hover': {
            background: "rgba(0, 163, 224, 1)",
        },
        outline: "none",
    },
    quizButtonWrapper: {
        width: "165px",
        marginTop: "38px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: "100%",
        }
    },
    arrowIcon: {
        transform: "rotate(180deg)",
        marginLeft: "9px"
    }
}));
