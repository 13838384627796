import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: theme.spacing(4, 1),
        },
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(10),
        color: "rgba(0, 0, 0, 0.87)",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            alignItems: "start",
            marginBottom: theme.spacing(5),
        },
    },
    imageContainer: {
        display: "flex",
        maxWidth: "894px",
        gap: theme.spacing(2),
        margin: "0 auto",
        justifyContent: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            overflowX: "auto",
            margin: 0,
            justifyContent: "unset",
            flexWrap: "unset"
        },
    },
    image: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        height: "282px",
        minWidth: "283px",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: theme.spacing(2),
        cursor: "pointer",
    },
}));
