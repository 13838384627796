import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(10),
        color: "rgba(0, 0, 0, 0.87)",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            alignItems: "start",
            marginBottom: theme.spacing(5),
        },
    },
}));
