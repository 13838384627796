import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        textDecoration: `none`,
        "&:hover": {
            textDecoration: `none`,
        },
    },
}));
