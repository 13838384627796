import React from "react";

import { DrawerWrapper, DialogWrapper } from "../../ModalWrapper/ModalWrapper";
import ModalHeader from "../../ModalHeader/ModalHeader";
import SplitText from "../../SplitText/SplitText";
import { useMediaWidthDownFrom } from "../../../utils/hooks";

import useStyles from "./SuccessStoriesModal.styles";

const SuccessStoriesModal = ({ isOpen, onClose, employeeStory }) => {
    const classes = useStyles();

    const isXsDown = useMediaWidthDownFrom("xs");

    const Wrapper = isXsDown ? DrawerWrapper : DialogWrapper;

    const handleOnBackClick = () => onClose();
    const handleOnClose = () => onClose();

    const renderView = () => {
        const image = (
            <div className={classes.image}
                 style={{backgroundImage: `url("${employeeStory.photo.url}")`}}>
            </div>
        );

        const header = (
            <ModalHeader
                className={!isXsDown ? classes.removePadding : undefined}
                onClose={!isXsDown ? handleOnClose : undefined}
                onBackClick={isXsDown ? handleOnBackClick : undefined}
            >
                {`${employeeStory.name}, ${employeeStory.role}`}
            </ModalHeader>
        );

        const description = (
            <SplitText
                text={employeeStory.description}
                variant="body1"
                className={classes.description}
            />
        );

        return {
            desktop: (
                <div className={classes.employeeStoryContainer}>
                    {image}
                    <div>
                        {header}
                        {description}
                    </div>
                </div>
            ),
            mobile: (
                <>
                    {header} {image} {description}
                </>
            ),
        };
    };

    return (
        employeeStory && (
            <Wrapper isOpen={isOpen} onClose={onClose}>
                {isXsDown ? renderView().mobile : renderView().desktop}
            </Wrapper>
        )
    );
};

export default SuccessStoriesModal;
