import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    description: {
        color: "rgba(0, 0, 0, 0.6)",
        padding: theme.spacing(3, 2),
        fontSize: "16px",
    },
    image: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        minWidth: "232px",
        height: "232px",
        marginTop: "15px",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: theme.spacing(2),
        marginRight: theme.spacing(6),
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            paddingTop: "100%",
            borderRadius: "0px",
            marginRight: 0,
        },
    },
    employeeStoryContainer: {
        display: "inline-flex",
        padding: theme.spacing(3, 5),
    },
    removePadding: {
        padding: 0,
    },
}));
