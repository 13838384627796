import { Typography, Link, IconButton } from "@material-ui/core";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

import Truncate from "react-truncate";

import React, { useState } from "react";

import { useMediaWidthDownFrom } from "../../utils/hooks";
import SuccessStoriesModal from "./SuccessStoriesModal/SuccessStoriesModal";
import { useActiveEmployeesView } from "./SuccessStories.hooks";
import useStyles from "./SuccessStories.styles";

const SuccessStories = ({ title, employeeStories, readMore }) => {
    const classes = useStyles();
    const [successStoriesModalOpen, setSuccessStoriesModalOpen] = useState(
        false
    );
    const [activeEmployeeStory, setActiveEmployeeStory] = useState(null);

    const handleReadMoreOnClick = (employeeStory) => {
        setSuccessStoriesModalOpen(true);
        setActiveEmployeeStory(employeeStory);
    };
    const handleSuccessStoriesModalOnClose = () => {
        setSuccessStoriesModalOpen(false);
        setActiveEmployeeStory(null);
    };

    const isXsDown = useMediaWidthDownFrom("xs");

    const {
        pagination,
        handleOnClickLeft,
        handleOnClickRight,
        activeEmployeeStories,
        getMaxPagination,
    } = useActiveEmployeesView(employeeStories);

    return (
        <div id={"success-stories"} className={classes.root}>
            <SuccessStoriesModal
                isOpen={successStoriesModalOpen}
                onClose={handleSuccessStoriesModalOnClose}
                employeeStory={activeEmployeeStory}
            />
            <Typography
                className={classes.title}
                variant={isXsDown ? "h4" : "h3"}
            >
                {title}
            </Typography>

            <div className={classes.employeeStoriesContainer}>
                {activeEmployeeStories.map(
                    ({ name, role, photo, description }) => (
                        <div
                            key={name + role}
                            className={classes.employeeInfoContainer}
                        >
                            <div className={`${classes.image} ${classes.hover}`}
                                 style={{backgroundImage: `url("${photo.url}")`}}
                                 onClick={() =>
                                     handleReadMoreOnClick({
                                         name,
                                         role,
                                         photo,
                                         description,
                                     })}>
                            </div>
                            <div className={`${classes.employeeInfo} ${classes.hover}`} onClick={() =>
                                handleReadMoreOnClick({
                                    name,
                                    role,
                                    photo,
                                    description,
                                })}>
                                <Typography
                                    className={classes.nameAndRole}
                                    variant="subtitle1"
                                >
                                    {`${name}, ${role}`}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.description}
                                >
                                    <Truncate lines={3} width={230}>
                                        {description}
                                    </Truncate>
                                </Typography>
                                <Link
                                    className={classes.readMore}
                                    onClick={() =>
                                        handleReadMoreOnClick({
                                            name,
                                            role,
                                            photo,
                                            description,
                                        })
                                    }
                                >
                                    {readMore}
                                </Link>
                            </div>
                        </div>
                    )
                )}
            </div>

            {!isXsDown && getMaxPagination() > 1 && (
                <div className={classes.paginationContainer}>
                    <IconButton
                        onClick={handleOnClickLeft}
                        className={classes.chevron}
                    >
                        <ChevronLeft />
                    </IconButton>

                    <Typography variant="body2">
                        {`${pagination}/${getMaxPagination()}`}
                    </Typography>

                    <IconButton
                        onClick={handleOnClickRight}
                        className={classes.chevron}
                    >
                        <ChevronRight />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export default SuccessStories;
