import React from "react";

import useStyles from "./Navigation.styles";
import { useMediaWidthDownFrom } from "../../utils/hooks";

import TopBar from "./TopBar/TopBar";
import Navbar from "./Navbar/Navbar";

const Navigation = ({ logo, links }) => {
    const classes = useStyles();
    const isXsDown = useMediaWidthDownFrom("xs");

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleTopBar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={classes.root}>
            {isXsDown ?
                <TopBar logo={logo} isOpen={isOpen} toggleTopBar={toggleTopBar} links={links} /> :
                <Navbar logo={logo} toggleTopBar={toggleTopBar} links={links} />}
        </div>
    );
};

export default Navigation;
