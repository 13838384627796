import {Typography} from "@material-ui/core";
import React from "react";
import {useMediaWidthDownFrom} from "../utils/hooks";

import useStyles from "./title.styles";

const Title = ({ children }) => {
    const classes = useStyles();
    const isXsDown = useMediaWidthDownFrom("xs");
    return (
        <Typography
            className={classes.title}
            variant={isXsDown ? "h4" : "h3"}
        >
            {children}
        </Typography>
    )
}

export default Title;
