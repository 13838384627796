import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: theme.spacing(4, 1),
        },
    },
    onlyDesktop: {
         [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "none!important",
        },
    },
    onlyMobile: {
         [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            display: "none!important",
        },
    },
    split: {
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            display: "flex",
            flexDirection: "row",
            padding: theme.spacing(0, 34, 0, 16),
        },
    },
    teams: {
        minWidth: 312,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginBottom: theme.spacing(3),
            fontSize: "16px",
        },
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            marginRight: theme.spacing(18)
        },
    },
    arrowIcon: {
        color: "#00A3E0",
    },
    hover: {
      "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
      }
    },
    paragraph: {
        letterSpacing: "0.5px",
        lineHeight: "24px",
        color: "rgba(0, 0, 0, 0.6)",
        textAlign: "justify",
        fontSize: "16px",
    },
    opportunities: {
        width: '100%'
    }
}));
