import React, { useState } from "react";

import Title from "../title";

import LifeAtRevelModal from "./LifeAtRevelModal/LifeAtRevelModal";
import useStyles from "./LifeAtRevel.styles";

const LifeAtRevel = ({ title, images }) => {
    const classes = useStyles();

    const [lifeAtRevelModalProps, setLifeAtRevelModalProps] = useState({
        isOpen: false,
        imageIndex: 0,
    });

    const handleOnImageClick = (index) => {
        setLifeAtRevelModalProps({ isOpen: true, imageIndex: index });
    };
    const handleLifeAtRevelModalOnClose = () =>
        setLifeAtRevelModalProps({ isOpen: false, imageIndex: 0 });

    return (
        <div className={classes.root}>
            <LifeAtRevelModal
                onClose={handleLifeAtRevelModalOnClose}
                images={images}
                isOpen={lifeAtRevelModalProps.isOpen}
                imageIndex={lifeAtRevelModalProps.imageIndex}
            />
            <Title>{title}</Title>
            <div className={classes.imageContainer}>
                {images.map(({ url }, index) => (
                    <div className={classes.image}
                         key={url}
                         style={{backgroundImage: `url("${url}")`}}
                         onClick={() => handleOnImageClick(index)}>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LifeAtRevel;
