import { Divider, Typography, Grid, Link } from "@material-ui/core";
import cn from "clsx";
import React from "react";

import useStyles from "./Footer.styles";
import CommonLink from "../CommonLink/CommonLink";

const Footer = ({
    socialMedia,
    socialMediaMessage,
    websiteName,
    copyrightMessage,
    copyrightLabel,
    leftLinks,
    rightLinks,
    logo,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                className={classes.gridContainer}
                container
                alignItems={"center"}
            >
                <Grid item xs={12} sm={4}>
                    <Link href={logo.caption} target="_blank">
                        <img className={classes.logo} src={logo.url} />
                    </Link>
                    <Typography variant="subtitle1" className={classes.color}>
                        {websiteName}
                    </Typography>
                </Grid>

                <Grid className={classes.sectionContainer} item xs={6} sm={2}>
                    {leftLinks.map((link) => (
                        <Typography
                            key={link.label}
                            className={cn([classes.section, classes.color])}
                            variant="body2"
                        >
                            <CommonLink link={link} linkColor="white" />
                        </Typography>
                    ))}
                </Grid>
                <Grid
                    className={cn([
                        classes.sectionContainer,
                        classes.extraPadding,
                    ])}
                    item
                    xs={6}
                    sm={2}
                >
                    {rightLinks.map((link) => (
                        <Typography
                            key={link.label}
                            className={cn([classes.section, classes.color])}
                            variant="body2"
                        >
                            <CommonLink link={link} linkColor="white" />
                        </Typography>
                    ))}
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.socialMediaContainer}
                >
                    <div>
                        <Typography
                            variant="h6"
                            className={cn([
                                classes.socialMediaMessage,
                                classes.color,
                            ])}
                        >
                            {socialMediaMessage}
                        </Typography>
                        <div className={classes.mediaContainer}>
                            {socialMedia.map((media) => (
                                <Link
                                    key={media.url}
                                    href={media.caption}
                                    target="_blank"
                                >
                                    <span className={classes.media}>
                                        <img src={media.url} />
                                    </span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <div className={classes.copyrightContainer}>
                <Typography variant="body2" className={classes.color}>
                    {copyrightLabel}
                </Typography>
                <Typography variant="body2" className={classes.color}>
                    {copyrightMessage}
                </Typography>
            </div>
        </div>
    );
};

export default Footer;
