import React from 'react';
import Title from "../title";
import useStyles from "./Careers.styles";
import {useFetch} from "../../hooks/useFetch";
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Select,
    Typography,
    MenuItem,
    InputLabel,
    FormControl
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const jobLocations = ['vilnius', 'lithuania', 'kaunas'];

const Careers = ({title, board_token, all_teams_text, teams_text, open_position_singular, open_position_plural, default_team_id }) => {
    const classes = useStyles();
    const [teamFilter, setTeamFilter] = React.useState(default_team_id ? Number(default_team_id) : '');
    const data = useFetch(`https://boards-api.greenhouse.io/v1/boards/${board_token}/jobs?content=true`);
    const selectTeamFilter = (id) => {
        setTeamFilter(id);
    }
    const jobs = data.data !== null ? data.data.jobs.filter(job => job.offices.some(office => jobLocations.some(location => office.location.toLowerCase().includes(location)))) : [];
    const teams = jobs.reduce((teams, {departments}) => {
        departments.forEach(department => {
            const team = teams.find(team => team.id === department.id);
            if (team) {
                team.jobs++;
            } else {
                teams.push({...department, jobs: 1});
            }
        });
        return teams;
    }, []);
    const jobsToDisplay = jobs.filter(({departments}) => teamFilter === '' || departments.some(department => department.id === teamFilter));
    const teamList = [{
        id: '',
        name: all_teams_text,
        jobs: jobs.length,
    }, ...teams]

    return <div id={"open-positions"} className={classes.root}>
        <Title>
            {title}
        </Title>
        <div className={classes.split}>
            <div className={classes.teams}>
                <List className={classes.onlyDesktop}>
                    {teamList.map(({jobs, id, name}) =>
                        <ListItem
                            className={classes.hover}
                            selected={id === teamFilter}
                            onClick={ev => {
                                selectTeamFilter(id);
                                ev.preventDefault();
                            }}
                            component="a"
                            href="#"
                        >
                            {name} ({jobs})
                        </ListItem>)}
                </List>
                <FormControl className={classes.onlyMobile} fullWidth>
                    <InputLabel shrink>{teams_text}</InputLabel>
                    <Select value={teamFilter} onChange={ev => selectTeamFilter(ev.target.value)} fullWidth displayEmpty>
                        {teamList.map(({jobs, id, name}) =>
                            <MenuItem
                                value={id}
                            >
                                {name} ({jobs})
                            </MenuItem>)}
                    </Select>
                </FormControl>

            </div>
            <div className={classes.opportunities}>
                <p className={`${classes.paragraph} ${classes.onlyDesktop}`}>
                    {jobsToDisplay.length} {jobsToDisplay.length === 1 ? open_position_singular : open_position_plural}
                </p>
                <List>
                    {
                        jobsToDisplay.map(({title, location, absolute_url}) =>
                            <ListItem className={classes.hover} component="a" href={absolute_url} target="_blank">
                                <ListItemText
                                    primary={
                                        <Typography variant="h5" component="div">
                                            {title}
                                        </Typography>
                                    }
                                    secondary={location.name}
                                />
                                <IconButton edge="end">
                                    <ArrowForwardIcon className={classes.arrowIcon} disableRipple disableFocusRipple />
                                </IconButton>
                            </ListItem>
                        )
                    }
                </List>
            </div>
        </div>
    </div>
}

export default Careers;
