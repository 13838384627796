import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(12.75, 0, 19.75, 0),
        position: "relative",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: theme.spacing(12.75, 0)
        },
    },
    container: {
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: "0px 8px"
        }
    },
    gridContainer: {
        display: "grid",
        gridTemplateColumns: `${theme.spacing(48.875)}px ${theme.spacing(48.875)}px`,
        gridColumnGap: theme.spacing(3),
        gridRowGap: theme.spacing(3),
        justifyContent: "center",
    },
    flexContainer: {
        display: "flex",
        padding: "0px",
        overflowX: "auto",
    },
    mainImage: {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: -1,
    },
    titleParagraph: {
        maxWidth: "778px",
        textAlign: "center",
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "rgba(0, 0, 0, 0.6)",
        margin: "0 auto 80px auto",
        fontSize: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            textAlign: "justify",
            letterSpacing: "0.15px",
            margin: "0 auto 49px auto",
        }
    },
    subtitleParagraph: {
        maxWidth: "555px",
        fontSize: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            textAlign: "center",
            letterSpacing: "0.5px",
        }
    },
    subtitleTitle: {
        marginTop: "181px",
        marginBottom: "31px",
        color: "rgba(0, 0, 0, 0.87)",
        textAlign: "center",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            textAlign: "none",
            marginTop: "110px",
            marginBottom: "20px",
        }
    },
    teamWrapper: {
        padding: "31px 19px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "flex",
            flexDirection: "column",
            background: "#FAFAFA",
            minWidth: "266px",
            minHeight: "339px",
            padding: "31px 19px 9px 19px",
            marginRight: "16px",
            '&:last-child': {
                marginRight: "0px",
            },
        }
    },
    teamParagraph: {
        textAlign: "unset",
        margin: "0px 0px 44px 0px",
        fontSize: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            margin: "0px 0px 36px 0px",
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            margin: "0"
        },
    },
    iconsArray: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            marginTop: "auto"
        }
    },
    iconMargin: {
        marginRight: "20px",
        '&:last-child': {
            marginRight: "0px",
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            margin: "0px 16px 16px 0px"
        }
    },
    teamTitle: {
        marginBottom: "15px"
    },
    quizButton: {
        padding: "6px 0px",
        width: "100%",
        fontWeight: "300",
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        color: "rgba(255, 255, 255, 1)",
        background: "rgba(0, 163, 224, 1)",
        '&:hover': {
            background: "rgba(0, 163, 224, 1)",
        },
        outline: "none"
    },
    quizButtonWrapper: {
        width: "170px",
        margin: "0 auto",
        marginTop: "38px",
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: "100%"
        }
    },
    arrowIcon: {
        transform: "rotate(180deg)",
        marginLeft: "9px"
    }
}));
