import { Button, Typography } from "@material-ui/core";
import React from "react";

import useStyles from "./ProductEngineering.styles";
import Title from "../title";
import cn from "clsx";
import { useMediaWidthDownFrom, useMediaWidthUpFrom } from "../../utils/hooks";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const ProductEngineering = ({
    title,
    text,
    subtitle,
    subtext,
    button,
    teams,
    image,
}) => {
    const classes = useStyles();
    const isMdUp = useMediaWidthUpFrom("md");

    return (
        <div id={"product-engineering"} className={classes.root}>
            <div className={classes.container}>
                <Title>{title}</Title>
                <p className={classes.titleParagraph}>{text}</p>
                <div
                    className={
                        !isMdUp ? classes.flexContainer : classes.gridContainer
                    }
                >
                    {teams.map((team) => (
                        <div className={classes.teamWrapper} key={team.id}>
                            <Typography
                                className={classes.teamTitle}
                                variant="h6"
                            >
                                {team.title}
                            </Typography>
                            <p
                                className={cn([
                                    classes.titleParagraph,
                                    classes.teamParagraph,
                                ])}
                            >
                                {team.text}
                            </p>
                            <div className={classes.iconsArray}>
                                {team.icons.map((icon) => (
                                    <img
                                        key={icon.url}
                                        className={classes.iconMargin}
                                        src={icon.url}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <Typography
                    className={classes.subtitleTitle}
                    variant={isXsDown ? "h4" : "h3"}
                >
                    {subtitle}
                </Typography>
                <p className={cn([classes.titleParagraph, classes.subtitleParagraph])}>
                    {subtext}
                </p>
                <div className={classes.quizButtonWrapper}>
                    <Button
                        className={classes.quizButton}
                        target={button.target}
                        href={button.url}
                    >
                        {button.label}
                        <KeyboardBackspaceIcon className={classes.arrowIcon}></KeyboardBackspaceIcon>
                    </Button>
                </div> */}
            </div>
            {/* {isMdUp &&
                <img className={classes.mainImage} src={image.url} alt=""/>
            } */}
        </div>
    );
};

export default ProductEngineering;
