import { useState, useEffect } from "react";

import { useMediaWidthDownFrom } from "../../utils/hooks";

export const useActiveEmployeesView = (employeeStories) => {
    const isXsDown = useMediaWidthDownFrom("xs");
    const NUMBER_OF_EMPLOYEES_PER_PAGE = 4;

    const getInitialActiveEmployees = (isXsDown) =>
        isXsDown
            ? employeeStories
            : employeeStories.slice(0, NUMBER_OF_EMPLOYEES_PER_PAGE);

    const getMaxPagination = () =>
        Math.ceil(employeeStories.length / NUMBER_OF_EMPLOYEES_PER_PAGE);

    const [activeEmployeeStories, setActiveEmployeeStories] = useState(
        getInitialActiveEmployees(isXsDown)
    );

    const [pagination, setPagination] = useState(1);

    const handleOnClickLeft = () => {
        setPagination(Math.max(pagination - 1, 1));
    };

    const handleOnClickRight = () => {
        setPagination(Math.min(pagination + 1, getMaxPagination()));
    };

    useEffect(() => {
        setActiveEmployeeStories(getInitialActiveEmployees(isXsDown));
    }, [isXsDown]);

    useEffect(() => {
        const start = (pagination - 1) * NUMBER_OF_EMPLOYEES_PER_PAGE;
        const end = pagination * NUMBER_OF_EMPLOYEES_PER_PAGE;

        setActiveEmployeeStories(
            employeeStories.slice(
                start - end !== NUMBER_OF_EMPLOYEES_PER_PAGE
                    ? end - NUMBER_OF_EMPLOYEES_PER_PAGE
                    : start,
                end
            )
        );
    }, [pagination]);

    return {
        pagination,
        handleOnClickLeft,
        handleOnClickRight,
        activeEmployeeStories,
        getMaxPagination,
    };
};
