import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 0),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: theme.spacing(4, 1),
        },
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(10),
        color: "rgba(0, 0, 0, 0.87)",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            alignItems: "start",
            marginBottom: theme.spacing(5),
        },
    },
    employeeStoriesContainer: {
        justifyContent: "center",
        display: "inline-flex",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: 0,
            overflowX: "auto",
            justifyContent: "space-between",
        },
    },
    employeeInfoContainer: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(7.5),
        [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
            marginRight: theme.spacing(2.5),
        },
        "&:last-child": {
            marginRight: 0,
        },
    },
    hover: {
        "&:hover": {
            cursor: "pointer"
        }
    },
    image: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      height: "232px",
      backgroundPosition: "50% 50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: theme.spacing(2),
    },
    employeeInfo: {
        width: theme.spacing(29),
        fontSize: "16px",
    },
    nameAndRole: {
        marginTop: theme.spacing(4.5),
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "16px",
    },
    description: {
        margin: theme.spacing(1, 0, 2),
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "16px",
        letterSpacing: "unset"
    },
    readMore: {
        cursor: "pointer",
        textDecoration: "underline",
        color: "#00A3E0",
    },
    paginationContainer: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "row",
        margin: "auto",
    },
    chevron: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        margin: theme.spacing(4.75, 4.5),
        "&:focus": {
            outline: 0,
        },
    },
}));
