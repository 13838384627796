import { Typography } from "@material-ui/core";
import React from "react";

import useStyles from "./TopBenefits.styles";
import Title from "../title";

const TopBenefits = ({ title, benefits }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Title>
                    {title}
                </Title>
                <div className={classes.gridContainer}>
                    {benefits.map(benefit =>
                        <div key={benefit.id}>
                           <div className={classes.benefitTitle} >
                                <img style={{marginRight: "12.5px"}} src={benefit.icon.url}/>
                                <Typography variant="h5">{benefit.title}</Typography>
                            </div>
                            <p className={classes.paragraph}>{benefit.text}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBenefits;
