import {AnchorLink} from "gatsby-plugin-anchor-links";
import React from "react";
import useStyles from "./OpenPositionsButton.styles";

const OpenPositionsButton = ({ toggleTopBar }) => {
    const classes = useStyles();

    return (
        <AnchorLink
            to="/#open-positions"
            title="Open Positions"
            className={classes.openPositionsButton}
            onAnchorLinkClick={toggleTopBar}
            // stripHash
        />
    )
};

export default OpenPositionsButton;
