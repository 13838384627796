import { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";

// Got from https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll() {
    useEffect(() => {
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;

        // Prevent scrolling on mount
        document.body.style.overflow = "hidden";

        // Re-enable scrolling when component unmounts
        return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
}

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

export const useMediaWidthDownFrom = (sizeFactor) => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(sizeFactor));
};

export const useMediaWidthUpFrom = (sizeFactor) => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(sizeFactor));
};
