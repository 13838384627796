import { Typography } from "@material-ui/core";
import React from "react";

const SplitText = ({ text, className, variant }) => (
    <Typography component={"span"} variant={variant} className={className}>
        {text.split("\n").map((str, i) => (
            <p key={str + i}>{str}&nbsp;</p>
        ))}
    </Typography>
);

export default SplitText;
