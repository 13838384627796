import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(15, 0),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: theme.spacing(9.1, 0),
        },
    },
    container: {
        marginTop: theme.spacing(20),
        display: "grid",
        gridTemplateColumns: `${theme.spacing(37.5)}px auto`,
        gridColumnGap: theme.spacing(12.5),
        gridRowGap: theme.spacing(12.5),
        justifyContent: "center",
        position: "relative",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: theme.spacing(15),
            marginLeft: theme.spacing(1),
            display: "flex",
            gridRowGap: "unset",
            gridColumnGap: "unset",
            flexDirection: "column",
        },
    },
    dataContainer: {
        paddingTop: theme.spacing(0.5),
        "& ~ $dataContainer": {
            paddingTop: 0,
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: theme.spacing(3.5),
            paddingTop: 0,
        },
    },
    text: {
        marginTop: theme.spacing(3),
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "16px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: theme.spacing(2.5),
        },
    },
    imageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        maxWidth: theme.spacing(100),
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(17),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            margin: theme.spacing(8, 0, 0, 1),
        },
    },
    titleColor: {
        color: "rgba(0, 0, 0, 0.87)",
    },
    description: {
        marginTop: theme.spacing(4),
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "16px",
    },
    buttonContainer: {
        marginTop: theme.spacing(15),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginTop: theme.spacing(4),
            padding: "0px 8px",
        },
    },
    button: {
        color: "#00A3E0",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        background: "#FFFFFF",
        padding: theme.spacing(0.5, 5),
        height: "40px",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: "100%",
        },
    },
    icon: {
        transform: "rotate(180deg)",
        marginLeft: theme.spacing(1),
    },
    leftIcon: {
        position: "absolute",
        left: "10%",
        transform: "translate(100%, -50%)",
        [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
            left: "5%",
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            right: 0,
            left: "unset",
            top: -theme.spacing(10),
            transform: "translateX(-50%)",
            paddingLeft: 0,
        },
    },
    rightIcon: {
        position: "absolute",
        right: 0,
        paddingTop: theme.spacing(16),
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            display: "none",
        },
    },
}));
