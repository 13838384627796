import {useEffect, useState, useRef} from 'react';

export const useFetch = (url) => {
    const isMounted = useRef(true);
    const [state, setState] = useState({
        error: false,
        isLoading: true,
        data: null,
    });

    useEffect(() => {
        const fetchData = () => {
            fetch(url).then(value => value.json()).then(data => {
                setState({
                    isLoading: false,
                    data,
                    error: false
                });
            }).catch(error => {
                if (isMounted.current) {
                    setState({
                        isLoading: false,
                        data: null,
                        error
                    })
                }
            })
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, []);

    return state;
};