import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    dialogWrapper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            boxShadow: "none",
        },
    },
    image: {
        width: "100%",
    },
    navButton: {
        backgroundColor: "transparent",
        "&:focus": {
            outline: "none",
        },
    },
}));
