import React from "react";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Carousel from "react-material-ui-carousel";

import { DialogWrapper } from "../../ModalWrapper/ModalWrapper";

import useStyles from "./LifeAtRevelModal.styles";

const LifeAtRevelModal = ({ isOpen, onClose, images, imageIndex }) => {
    const classes = useStyles();

    return (
        <DialogWrapper
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={"sm"}
            className={classes.dialogWrapper}
        >
            <Carousel
                autoPlay={false}
                navButtonsAlwaysVisible
                indicators={false}
                index={imageIndex}
                PrevIcon={<ChevronLeft />}
                NextIcon={<ChevronRight />}
                navButtonsProps={{ className: classes.navButton }}
                timeout={200}
            >
                {images.map(({ url }) => (
                    <img className={classes.image} key={url} src={url} />
                ))}
            </Carousel>
        </DialogWrapper>
    );
};

export default LifeAtRevelModal;
