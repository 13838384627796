import { Link } from "@material-ui/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import cn from "clsx";

import useStyles from "./CommonLink.styles";

const CommonLink = ({
    link,
    toggleTopBar,
    className,
    linkColor = "rgba(0, 0, 0, 0.87)",
}) => {
    const classes = useStyles();
    const conditions = ["www", "http"];

    return conditions.some((el) => link.url.includes(el)) ? (
        <Link
            style={{ color: linkColor }}
            className={cn([classes.root, className])}
            href={link.url}
            target={link.target}
        >
            {link.label}
        </Link>
    ) : (
        <span style={{ color: linkColor }} className={className}>
            <AnchorLink
                to={link.url}
                className={classes.root}
                title={link.label}
                onAnchorLinkClick={toggleTopBar}
            >
                {link.label}
            </AnchorLink>
        </span>
    );
};

export default CommonLink;
