import { Typography } from "@material-ui/core";
import React from "react";

import useStyles from "./OurStructure.styles";
import Title from "../title";

const OurStructure = ({ title, departments }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Title>
                    {title}
                </Title>
                <div className={classes.gridContainer}>
                    {departments.map(department =>
                        <div key={department.id}>
                            <Typography variant="h5">{department.title}</Typography>
                            <p className={classes.paragraph}>{department.text}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurStructure;
