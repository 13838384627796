import { Button, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import React from "react";

import SplitText from "../SplitText/SplitText";
import { useMediaWidthDownFrom } from "../../utils/hooks";

import useStyles from "./AboutUs.styles";

const AboutUs = ({
    title,
    description,
    image,
    subHeader,
    button,
    data,
    leftIcon,
    rightIcon,
}) => {
    const classes = useStyles();

    const isXsDown = useMediaWidthDownFrom("xs");

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                <img src={image.url} />
                <div className={classes.titleContainer}>
                    <Typography variant={"h3"} className={classes.titleColor}>
                        {title}
                    </Typography>
                    <SplitText
                        text={description}
                        variant="body1"
                        className={classes.description}
                    />
                </div>
            </div>

            <div className={classes.container}>
                <img src={leftIcon.url} className={classes.leftIcon} />

                <div className={classes.column}>
                    <Typography
                        variant={isXsDown ? "h4" : "h3"}
                        className={classes.titleColor}
                    >
                        {subHeader}
                    </Typography>
                </div>
                {data.map(({ title, text }) => (
                    <div key={title} className={classes.dataContainer}>
                        <Typography
                            variant={isXsDown ? "h6" : "h5"}
                            className={classes.titleColor}
                        >
                            {title}
                        </Typography>
                        <Typography variant="body1" className={classes.text}>
                            {text}
                        </Typography>
                    </div>
                ))}

                <img src={rightIcon.url} className={classes.rightIcon} />
            </div>

            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    target={button.target}
                    href={button.url}
                >
                    {button.label}
                    <KeyboardBackspaceIcon className={classes.icon} />
                </Button>
            </div>
        </div>
    );
};

export default AboutUs;
