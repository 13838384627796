import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    drawerPaper: {
        width: "100%",
        overflowX: "hidden",
        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
            width: "50%",
        },
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            width: "40%",
        },
        [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
            width: "35%",
        },
        [theme.breakpoints.up(theme.breakpoints.values.xl)]: {
            minWidth: "30%",
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: "100%",
        },
    },
    dialogPaper: {
        borderRadius: theme.spacing(2),
    },
}));
